import { memo, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'

import { Box, Paper } from '@mui/material'
import Typography from '@mui/material/Typography'
import { FieldWithButton, HelperTextInfoType, MainAccordion } from '@rent/ui'
import { containerSpacings } from 'components/shared/styledComponents'

const containerStyle = {
  mb: 1.2,
  ...containerSpacings,
}
interface PromoCodeBoxProps {
  promoInfo: HelperTextInfoType | null
  onSubmitForm: (value?: string) => void
  promoCode?: string
}

const PromoCodeBox = memo(({ promoInfo, onSubmitForm, promoCode }: PromoCodeBoxProps) => {
  const { formatMessage } = useIntl()

  const [promoCodeInfo, setPromoCodeInfo] = useState<HelperTextInfoType | null>(null)

  const inputRef = useRef<HTMLInputElement>(null)

  const accordionTitle = <Typography color="text.secondary">{formatMessage({ id: 'do_you_have_a_promo_code' })}</Typography>

  const handleToggleCallback = (active: boolean) => {
    if (active) {
      inputRef?.current && inputRef.current.focus()
    }
  }

  useEffect(() => {
    setPromoCodeInfo(promoInfo)
  }, [promoInfo])

  return (
    <Paper sx={containerStyle}>
      {promoCode ? (
        <Box>
          <Typography variant="body2" mb={1}>
            {formatMessage(
              { id: 'notification.super_promocode_applied' },
              {
                val: (
                  <Typography variant="subtitle1" color="success.main" component="span">
                    {promoCode}
                  </Typography>
                ),
              },
            )}
          </Typography>
        </Box>
      ) : (
        <MainAccordion title={accordionTitle} titleColor="text.secondary" handleToggleCallback={handleToggleCallback} id="promocode-expand">
          <FieldWithButton
            placeholder={formatMessage({ id: 'form.provide_promocode_placeholder' })}
            buttonText={formatMessage({ id: 'button.confirm' })}
            onSubmit={onSubmitForm}
            successInfo={promoCodeInfo}
            inputProps={{
              fullWidth: true,
              inputRef,
            }}
            name="promocode"
            sx={{ mt: 1.6 }}
          />
        </MainAccordion>
      )}
    </Paper>
  )
})
export default PromoCodeBox
