import { useEffect, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import Typography from '@mui/material/Typography'
import { RadioGroup } from '@rent/ui'
import { InsuranceType } from 'consts'
import { calcProductPrice } from 'helpers'
import { IMappedProduct } from 'models'

import { SafeUpChangeConfirmation } from './SafeUpChangeConfirmation'

interface InsuranceSelectionProps {
  product: IMappedProduct
  handleSetSafeUpPlus: (safeupPlusTaken: boolean) => void
}

const InsuranceSelection = ({ handleSetSafeUpPlus, product }: InsuranceSelectionProps) => {
  const { formatMessage } = useIntl()

  const [openDialog, setOpenDialog] = useState(false)

  const calculatedProduct = useMemo(() => {
    return calcProductPrice(product)
  }, [product])

  const insuranceData = useMemo(() => {
    const insurances: {
      title: string
      subtitle?: string
      key: string
      id: string
      content: React.ReactNode
      rightText: React.ReactNode
    }[] = [
      {
        title: formatMessage({ id: 'insurance_basic' }),
        content: product.safeUpProvider?.content,
        rightText: formatMessage({ id: 'in_price' }),
        key: InsuranceType.SAFE_UP,
        id: InsuranceType.SAFE_UP + '-radio',
      },
    ]
    if (product.safeupPlusPrice) {
      const safeupPlus = {
        title: formatMessage({ id: 'insurance_extended' }),
        content: product.safeUpProvider?.safeUpPlusContent,
        rightText: (
          <>
            {calculatedProduct.totalPriceWithDiscount.toFixed(2)} + {product.safeupPlusPrice} {formatMessage({ id: 'PLN' })}{' '}
            <Typography variant="inherit" component="span" sx={{ fontSize: '1.4rem' }}>
              {formatMessage({ id: 'net' })}/{formatMessage({ id: 'mth' })}
            </Typography>
          </>
        ),
        key: InsuranceType.SAFE_UP_PLUS,
        id: InsuranceType.SAFE_UP_PLUS + '-radio',
      }
      insurances.push(safeupPlus)
    }
    return insurances
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product])

  useEffect(() => {
    if (product.safeupPlusPrice) {
      handleSetSafeUpPlus(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.safeupPlusPrice])

  const handleChange = (val: string) => {
    if (val === InsuranceType.SAFE_UP) {
      setOpenDialog(true)
    } else {
      handleSetSafeUpPlus(val === InsuranceType.SAFE_UP_PLUS)
    }
  }

  return (
    <>
      <RadioGroup
        items={insuranceData}
        sx={{ '&:not(:last-child)': { mb: 1 } }}
        selectedValue={product.safeupPlusTaken ? InsuranceType.SAFE_UP_PLUS : InsuranceType.SAFE_UP}
        onChange={handleChange}
      />
      {openDialog && <SafeUpChangeConfirmation handleSetSafeUpPlus={handleSetSafeUpPlus} setOpenDialog={setOpenDialog} />}
    </>
  )
}

export default InsuranceSelection
