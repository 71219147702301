const safeup = {
  confirmCancel: 'Czy na pewno chcesz zrezygnować z SafeUp+?',
  safeUpDescription: 'SafeUp+ to większe oszczędności i pełna ochrona urządzenia.',
  cancelWarning:
    '<strong>Rezygnacja</strong> z pakietu SafeUp+ <strong>może znacząco zwiększyć</strong> koszty napraw - w zależności od sprzętu i rodzaju uszkodzenia <strong>nawet do 1000 zł lub więcej</strong>. Z SafeUp+ <strong>zmniejszysz wydatki</strong> i zyskasz poczucie <strong>bezpieczeństwa</strong>.',
  safeUpPlusBenefits: 'SafeUp+ ma wiele korzyści',
  contractDuration: 'Umowa trwa 12 miesięcy',
  peaceOfMind: 'Zyskaj poczucie bezpieczeństwa na okres trwania całej umowy',
  lowerCosts: 'Niższe koszty',
  avoidHigherRepairCosts: 'Uniknij wyższych kosztów w przypadku naprawy',
  keepSafeUpPlus: 'Zostawiam SafeUp+',
  cancelSafeUpPlus: 'Rezygnuję',
}

export { safeup }
